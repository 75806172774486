// NOTE enter the name of the field to format
export const FIELD_NAMES = {
    // usual field with number
    insuranceLimit: 'insuranceLimit',
    insuranceDeductible: 'insuranceDeductible',
    averageRevenue: 'averageRevenue',
    marketCapitalizationNumber: 'marketCapitalizationNumber',
    numberOfRecProcessed: 'numberOfRecProcessed',
    costToRestore: 'costToRestore',
    revenueProcessed: 'revenueProcessed',
    recordPriceLimit: 'recordPriceLimit',
    maximumWrittenPremium: 'maximumWrittenPremium',
    maximumCarrierLimit: 'maximumCarrierLimit',
    ransomwareSublimit: 'ransomwareSublimit',
    privacyGdprSublimit: 'privacyGdprSublimit',
    privacyCcpaSublimit: 'privacyCcpaSublimit',
    ddosSublimit: 'ddosSublimit',
    revenue: 'revenue',
    ebitda: 'ebitda',
    debt: 'debt',
    pensionDebt: 'pensionDebt',
    grossRiskBearingCapacity: 'grossRiskBearingCapacity',
    businessRiskBearingCapacity: 'businessRiskBearingCapacity',
    cyberRiskBearingCapacity: 'cyberRiskBearingCapacity',
    value: 'value',
    metricFormulaItems: 'metricFormulaItems',
    formulaItems: 'formula.formulaItems',
    totalCosts: 'totalCosts',
    formValues: 'formValues',
    licenseCost: 'licenseCost',
    equipmentCost: 'equipmentCost',
    personnelCost: 'personnelCost',
    toolPrice: 'toolPrice',
    totalHeadCount: 'totalHeadCount',
    companyITBudget: 'companyITBudget',
    itHeadCount: 'itHeadCount',
    securityHeadCount: 'securityHeadCount',
    itSecurityBudget: 'itSecurityBudget',
    inhouseDataCentersCount: 'inhouseDataCentersCount',
    externalDataCentersCount: 'externalDataCentersCount',
    personallyIdentifiableInformation: 'personallyIdentifiableInformation',
    paymentCardIndustry: 'paymentCardIndustry',
    internetOfThings: 'internetOfThings',
    industrialControlSystems: 'industrialControlSystems',

    // field-array (field that is an array)
    geoRecordsProcessed: 'geoRecordsProcessed',
};

/**
 * @description method of separating a numeric value with a comma and vice versa convert to a number
 * @param {Object|Array} data
 * @returns {Object|Array}
 * @public
 */
function formatDataField(data) {
    // NOTE method for data as Array. Return []
    if (Array.isArray(data)) {
        return data.forEach(item => {
            // NOTE after checking typeof value of the number field - separate with commas or convert to default numeric
            for (let field in FIELD_NAMES) {
                if (item[field] && !Array.isArray(item[field])) {
                    item[field] = typeof item[field] === 'number' ? item[field].toLocaleString('en') : Number(item[field].replace(/[^\d]/g, ''));
                }

                // NOTE implementation for array-fields (field that is an array). Checking an array-field for comma separated numbers
                if (Array.isArray(item[field])) {
                    item[field].forEach(item => {
                        for (let field in FIELD_NAMES) {
                            if (item[field]) {
                                item[field] = typeof item[field] === 'number' ? item[field].toLocaleString('en') : Number(item[field].replace(/[^\d]/g, ''));
                            }
                        }
                    });
                }
            }
        });

        // NOTE method for data as Object. Return {}
    } else {
        // NOTE after checking typeof value of the  number field - separate with commas or convert to default numeric
        for (let field in FIELD_NAMES) {
            if (data[field] && !Array.isArray(data[field])) {
                data[field] = typeof data[field] === 'number' ? data[field].toLocaleString('en') : Number(data[field].replace(/[^\d]/g, ''));
            }

            // NOTE implementation for array-fields (field that is an array). Checking an array-field for comma separated numbers
            if (Array.isArray(data[field])) {
                data[field].forEach(item => {
                    for (let field in FIELD_NAMES) {
                        if (item[field]) {
                            item[field] = typeof item[field] === 'number' ? item[field].toLocaleString('en') : Number(item[field].replace(/[^\d]/g, ''));
                        }
                    }
                });
            }
        }
        return data;
    }

}

/**
 * @description method separating a numeric value with a comma ( for normalize={separateWithComma} )
 * @param {Number|String} value
 * @returns {Number|String}
 * @public
 */
function separateWithComma(value) {
    if (!value) {
        return value;
    }
    let sign = value.startsWith('-') ? '-' : '';
    return Number(sign + value.replace(/[^\d]/g, '')).toLocaleString('en');
}


/**
 * @description method of separating a real number with a comma and vice versa convert to a real number
 * @param {Object|Array} data
 * @returns {Object|Array}
 * @public
 */
function formatDataFieldReal(data) {
    // NOTE method for data as Array. Return []
    if (Array.isArray(data)) {
        return data.forEach(item => {
            // NOTE after checking typeof value of the number field - separate with commas or convert to default numeric
            for (let field in FIELD_NAMES) {
                if (item[field] && !Array.isArray(item[field])) {
                    item[field] = typeof item[field] === 'number' ? item[field].toLocaleString('en') : Number(item[field].replace(/[^.\d]/g, ''));
                }

                // NOTE implementation for array-fields (field that is an array). Checking an array-field for comma separated numbers
                if (Array.isArray(item[field])) {
                    item[field].forEach(item => {
                        for (let field in FIELD_NAMES) {
                            if (item[field]) {
                                item[field] = typeof item[field] === 'number' ? item[field].toLocaleString('en') : Number(item[field].replace(/[^.\d]/g, ''));
                            }
                        }
                    });
                }
            }
        });

        // NOTE method for data as Object. Return {}
    } else {
        // NOTE after checking typeof value of the  number field - separate with commas or convert to default numeric
        for (let field in FIELD_NAMES) {
            if (data[field] && !Array.isArray(data[field])) {
                data[field] = typeof data[field] === 'number' ? data[field].toLocaleString('en') : Number(data[field].replace(/[^.\d]/g, ''));
            }

            // NOTE implementation for array-fields (field that is an array). Checking an array-field for comma separated numbers
            if (Array.isArray(data[field])) {
                data[field].forEach(item => {
                    for (let field in FIELD_NAMES) {
                        if (item[field]) {
                            item[field] = typeof item[field] === 'number' ? item[field].toLocaleString('en') : Number(item[field].replace(/[^.\d]/g, ''));
                        }
                    }
                });
            }
        }
        return data;
    }

}

/**
 * @description method separating a real number with a comma ( for normalize={separateWithComma} )
 * @param {Number|String} value
 * @returns {Number|String}
 * @public
 */
function separateWithCommaReal(value) {
    if (!value) {
        return value;
    }
    let valueWithDot;
    valueWithDot = value.replace(/[^.\d]/g, '');
    let sign = value.startsWith('-') ? '-' : '';
    if (valueWithDot.split('.').length > 2) {
        return Number(sign + value.replace(/[\D]/g, '')).toLocaleString('en');
    }
    if (valueWithDot.endsWith('.')) {
        return sign + value.replace(/[^.,\d]/g, '');
    }
    if (valueWithDot.split('.').length > 1) {
        return Number(sign + value.replace(/[^.\d]/g, '')).toLocaleString('en');
    }
    return Number(sign + valueWithDot.replace(/[\D]/g, '')).toLocaleString('en');
}

// named export * as ...
export {formatDataField, separateWithComma, formatDataFieldReal, separateWithCommaReal};

export default {
    separate: formatDataField,
    convert: formatDataField,
    normalize: separateWithComma,
    separateReal: formatDataFieldReal,
    convertReal: formatDataFieldReal,
    normalizeReal: separateWithCommaReal
};
