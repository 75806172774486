// outsource dependencies
import {get} from 'lodash';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {Col, Row} from 'react-bootstrap';
import {CloudDownload, CloudUpload, HelpOutline} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {withStyles} from '@mui/styles'

// local dependencies
import MdInput from './md-input';
import {MdSelect} from './md-select';
import {PrimaryBtn} from './md-button';
import {translate, withTranslation} from '../services/translate.service';
import withDownloadLink from "./download-link";
import {DOWNLOAD} from "../constants/routes";

/**
 * Question Row component with form controls and branching logic handling
 *
 * @param {Object} props
 * @private
 */
export const QuestionRow = withTranslation(({input, meta, uploadFile, disabled}) => {

    if (get(meta, 'warning.isHidden')) return null;

    let hasAnswers = Boolean(get(input, 'value.answers.length', 0));
    let allowTextAsAnswer = Boolean(get(input, 'value.allowTextAsAnswer', false));
    let allowUploadAsAnswer = Boolean(get(input, 'value.allowUploadAsAnswer', false));
    let allowCommentToAnswer = Boolean(get(input, 'value.allowCommentToAnswer', false));
    let useColorCoding = Boolean(get(input, 'value.useColorCoding', false));
    let selectedAnswer = get(input, 'value.selectedAnswers', null);
    let colorCodingInfo = null;
    if (useColorCoding) {
        colorCodingInfo = getColorCodingInfo(selectedAnswer, get(input, 'value.answers', []));
    }

    let selectedAnswersString = '';
    let selectedAnswersText = get(input, 'value.answerText', []);
    let selectedAnswersComment = get(input, 'value.answerComment', []);
    if (selectedAnswer && selectedAnswer.length) {
        for (let i = 0; i < selectedAnswer.length; i++) {
            selectedAnswersString += (i > 0 ? '<br />' : '') + selectedAnswer.answer;
        }
    } else if (selectedAnswer) {
        selectedAnswersString = selectedAnswer.answer;
    }

    return (
        <Row className={(useColorCoding ? 'color-coding' : '')} style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: "1px dotted", marginTop: -1}}>
            {get(input, 'value.questionCategory') && (
                <Col xs={12} lg={12} className="header-row-title">
                    <h3><span>{get(input, 'value.questionCategory')}</span></h3>
                </Col>
            )}
            <Col xs={6} lg={6} md={6} className="offset-bottom-2">
                {/*{!get(input, 'value.questionCategory') && get(input, 'name') === 'questions[0]'*/}
                {/*    && <Row className="header-row-title"><h4><strong>{translate("QUESTION_ROW$QUESTION")}</strong></h4></Row>}*/}

                <Row>
                    {get(input, 'value.article') && (
                        <h4 className="offset-top-1">
                            <small><strong>{get(input, 'value.article.articleNumber')} - {get(input, 'value.article.name')}</strong></small>
                        </h4>
                    )}
                    <h4 className="offset-top-1">
                        <span>{get(input, 'value.question')}</span>&nbsp;
                        {get(input, 'value.description') && (<span className="no-print"><DescriptionTooltip title={get(input, 'value.description')} /></span>)}
                    </h4>
                </Row>
            </Col>
            <Col xs={6} lg={6} md={6} className="offset-bottom-2">
                {/*{!get(input, 'value.questionCategory') && get(input, 'name') === 'questions[0]'*/}
                {/*    && <Row className="header-row-title"><h4><strong>{translate("QUESTION_ROW$ANSWER")}</strong></h4></Row>}*/}
                <Row>
                    {allowUploadAsAnswer && (<Col xs={12} className="top-indent-2 offset-bottom-2">
                        <span className="no-print">
                            <Field
                                disabled={disabled}
                                uploadFile={uploadFile}
                                component={UploadAnswersInput}
                                name={`${get(input, 'name')}.document`}
                            />
                        </span>
                         &nbsp;
                        {get(input, 'value.document') && <strong className="text-nowrap offset-left-3">{translate("GLOBALS$DOCUMENT")}: {get(input, 'value.document.fileName')}</strong>}
                        {get(input, 'value.document') && <DownloadLink className="offset-left-3 offset-bottom-0"
                            link={() => DOWNLOAD.QUALITATIVE_QUESTION_ANSWER_DOCUMENT_LINK({downloadUrl: get(input, 'value.document.downloadUrl')})}
                        />}
                    </Col>)}
                    {hasAnswers && (<Col xs={12} className="offset-bottom-2">
                        <div className="no-print offset-top-1">
                            <Field
                                valueKey="id"
                                labelKey="answer"
                                disabled={disabled}
                                component={MdSelect}
                                className={(useColorCoding && colorCodingInfo ? colorCodingInfo.styleName : '')}
                                options={get(input, 'value.answers', [])}
                                name={`${get(input, 'name')}.selectedAnswers`}
                                placeholder={translate("SCORING_QUESTIONS$SELECT_ANSWER")}
                                helpText={(useColorCoding && colorCodingInfo ? colorCodingInfo.hint : null)}
                            />
                        </div>
                        <div className="print-hidden">{selectedAnswersString}</div>
                        {useColorCoding && colorCodingInfo ? (<div className="print-hidden">{colorCodingInfo.hint}</div>) : ''}
                    </Col>)}
                    {allowTextAsAnswer && (<Col xs={12} className="offset-bottom-2">
                        <div className="no-print">
                            <Field
                                multiline={true}
                                component={MdInput}
                                disabled={disabled}
                                name={`${get(input, 'name')}.answerText`}
                                placeholder={translate("SCORING_QUESTIONS$ANSWER_TEXT")}
                            />
                        </div>
                        <div className="print-hidden">{selectedAnswersText}</div>
                    </Col>)}
                    {allowCommentToAnswer && (<Col xs={12} className="offset-bottom-2">
                        <div className="no-print">
                            <Field
                                multiline={true}
                                component={MdInput}
                                disabled={disabled}
                                name={`${get(input, 'name')}.answerComment`}
                                placeholder={translate("SCORING_QUESTIONS$ANSWER_COMMENT")}
                            />
                        </div>
                        <div className="print-hidden">{selectedAnswersComment}</div>
                    </Col>)}
                </Row>
            </Col>
        </Row>
    )
});

function getColorCodingInfo(selectedAnswer, answers) {
    let maxAnswerWeight = 5;
    for (let key in answers) {
        if (answers[key].answerWeight && answers[key].answerWeight.value && answers[key].answerWeight.value > maxAnswerWeight) {
            maxAnswerWeight = answers[key].answerWeight.value;
        }
    }

    let scalingFactor = 2;
    if (maxAnswerWeight > 5) {
        maxAnswerWeight = 10;
        scalingFactor = 1;
    }

    let result = {};

    if (selectedAnswer && selectedAnswer.answerWeight) {
        let selectedAnswerWeight = selectedAnswer.answerWeight.value;
        let scaledWeight = selectedAnswerWeight * scalingFactor;

        let styleName = '';
        if (scaledWeight > 8) {
            styleName = 'color-coding-most';
            result.hint = translate('SCORING_QUESTIONS$COLOR_CODING$MOST');
        } else if (scaledWeight > 6) {
            styleName = 'color-coding-significant';
            result.hint = translate('SCORING_QUESTIONS$COLOR_CODING$SIGNIFICANT');
        } else if (scaledWeight > 4) {
            styleName = 'color-coding-moderate';
            result.hint = translate('SCORING_QUESTIONS$COLOR_CODING$MODERATE');
        } else if (scaledWeight > 2) {
            styleName = 'color-coding-least';
            result.hint = translate('SCORING_QUESTIONS$COLOR_CODING$LEAST');
        } else {
            styleName = 'color-coding-minimal';
            result.hint = translate('SCORING_QUESTIONS$COLOR_CODING$MINIMAL');
        }

        result.styleName = styleName;
    }

    return result;
}

QuestionRow.propTypes = {
    disabled: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    uploadFile: PropTypes.func.isRequired,
};

export const CategoryQuestions = withTranslation(({input, meta, uploadFile, disabled, answersMap, category}) => {

    if (input && input.value && input.value.branchingLogic && input.value.branchingLogic.length) {
        let isVisible = true;
        for (let i = 0; i < input.value.branchingLogic.length; i++) {
            let branchingLogic = input.value.branchingLogic[i];
            if (branchingLogic.question && branchingLogic.question.id && branchingLogic.answer && branchingLogic.answer.id && answersMap) {
                let currentBranchingAnswer = answersMap[branchingLogic.question.id];
                if (!currentBranchingAnswer || currentBranchingAnswer.id !== branchingLogic.answer.id) {
                    isVisible = false;
                }
            }
        }
        if (!isVisible) {
            return null;
        }
    }
    // if (get(meta, 'warning.isHidden')) return null;

    let hasAnswers = Boolean(get(input, 'value.answers.length', 0));
    let allowTextAsAnswer = Boolean(get(input, 'value.allowTextAsAnswer', false));
    let allowUploadAsAnswer = Boolean(get(input, 'value.allowUploadAsAnswer', false));
    let allowCommentToAnswer = Boolean(get(input, 'value.allowCommentToAnswer', false));
    let questionCategory = get(input, 'value.categoryName', '');
    if (category === 'Uncategorized') {
        category = null;
    }
   if  (category !== questionCategory)  return null;

    let useColorCoding = Boolean(get(input, 'value.useColorCoding', false));
    let selectedAnswer = get(input, 'value.selectedAnswers', null);
    let colorCodingInfo = null;
    if (useColorCoding) {
        colorCodingInfo = getColorCodingInfo(selectedAnswer, get(input, 'value.answers', []));
    }

    return (
        <Row className={(useColorCoding ? 'color-coding' : '')} style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', border: "1px dotted", marginTop: -1}}>

            <Col xs={12} lg={6} className="offset-bottom-2">
                {/*{!get(input, 'value.questionCategory') && get(input, 'name') === 'questions[0]'*/}
                {/*    && <Row className="header-row-title"><h4><strong>{translate("QUESTION_ROW$QUESTION")}</strong></h4></Row>}*/}

                <Row>
                    {get(input, 'value.article') && (
                        <h4 className="offset-0">
                            <small><strong>{get(input, 'value.article.articleNumber')} - {get(input, 'value.article.name')}</strong></small>
                        </h4>
                    )}
                    <h4 className="offset-0">
                        <span>{get(input, 'value.question')}</span>&nbsp;
                        {get(input, 'value.description') && (<DescriptionTooltip title={get(input, 'value.description')} />)}
                    </h4>
                </Row>
            </Col>
            <Col xs={12} lg={6} className="offset-bottom-2">
                {/*{!get(input, 'value.questionCategory') && get(input, 'name') === 'questions[0]'*/}
                {/*    && <Row className="header-row-title"><h4><strong>{translate("QUESTION_ROW$ANSWER")}</strong></h4></Row>}*/}
                <Row>
                    {allowUploadAsAnswer && (<Col xs={12} className="top-indent-2 offset-bottom-2">
                        <Field
                            disabled={disabled}
                            uploadFile={uploadFile}
                            component={UploadAnswersInput}
                            name={`${get(input, 'name')}.document`}
                        /> &nbsp;
                        {get(input, 'value.document') && <strong className="text-nowrap">{translate("GLOBALS$DOCUMENT")}: {get(input, 'value.document.fileName')}</strong>}
                    </Col>)}
                    {hasAnswers && (<Col xs={12} className="offset-bottom-2">
                        <Field
                            valueKey="id"
                            labelKey="answer"
                            disabled={disabled}
                            component={MdSelect}
                            className={(useColorCoding && colorCodingInfo ? colorCodingInfo.styleName : '')}
                            options={get(input, 'value.answers', [])}
                            name={`${get(input, 'name')}.selectedAnswers`}
                            placeholder={translate("SCORING_QUESTIONS$SELECT_ANSWER")}
                            helpText={(useColorCoding && colorCodingInfo ? colorCodingInfo.hint : null)}
                        />
                    </Col>)}
                    {allowTextAsAnswer && (<Col xs={12} className="offset-bottom-2">
                        <Field
                            multiline={true}
                            component={MdInput}
                            disabled={disabled}
                            name={`${get(input, 'name')}.answerText`}
                            placeholder={translate("SCORING_QUESTIONS$ANSWER_TEXT")}
                        />
                    </Col>)}
                    {allowCommentToAnswer && (<Col xs={12} className="offset-bottom-2">
                        <Field
                            multiline={true}
                            component={MdInput}
                            disabled={disabled}
                            name={`${get(input, 'name')}.answerComment`}
                            placeholder={translate("SCORING_QUESTIONS$ANSWER_COMMENT")}
                        />
                    </Col>)}
                </Row>
            </Col>
        </Row>
    )
});


CategoryQuestions.propTypes = {
    disabled: PropTypes.bool,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    uploadFile: PropTypes.func.isRequired,
    category: PropTypes.string.isRequired,
};

/**
 * Upload Answers Input
 *
 * @param {Object} props
 * @private
 */
const UploadAnswersInput = withTranslation(({input, disabled, uploadFile}) => (<Fragment>
    <input
        type="file"
        id={input.name}
        accept=".pdf, .doc, .docx, .csv, .xls, .xlsx, .xlsm, application/msword"
        style={{display: 'none'}}
        onChange={e=>e&&uploadFile(e.target.files[0], input.name)}
            />
    <label htmlFor={input.name}>
        <PrimaryBtn tooltip={translate("GLOBALS$DOCUMENT_UPLOAD")} disabled={disabled} component="span">
            <CloudUpload fontSize="small" />&nbsp;{translate("GLOBALS$DOCUMENT_UPLOAD")}
        </PrimaryBtn>
    </label>
</Fragment>));

UploadAnswersInput.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired,
    uploadFile: PropTypes.func.isRequired,
};

/**
 * Upload Answers Input
 *
 * @param {Object} props
 * @private
 */
export const DescriptionTooltip = withStyles(theme => ({ tooltip: { padding: 10, fontSize: 14, backgroundColor: theme.palette.grey[800] } }))(
    ({classes, title=''}) => (
        <Tooltip title={title} classes={{ tooltip: classes.tooltip }}><span>
            <IconButton color="primary" aria-label={translate("GLOBALS$DESCRIPTION")} style={{padding: 6}}>
                <HelpOutline fontSize="small" />
            </IconButton>
        </span></Tooltip>
    )
);

const DownloadLink = withTranslation(withDownloadLink()(props => (
    <Tooltip title={translate('GLOBALS$DOWNLOAD')} className="offset-bottom-1"><span>
        <IconButton {...props} color="primary" style={{padding: '5px'}} aria-label={translate('GLOBALS$DOWNLOAD')}>
            <CloudDownload fontSize="large"/>
        </IconButton>
    </span></Tooltip>
)));
