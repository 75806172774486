// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {AccountBalance} from '@mui/icons-material';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Paper} from '@mui/material';

// local dependencies
import {EDIT} from './actions';
import {FORM_NAME} from './saga';
import Input from '../../components/md-input';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {PERMISSION} from '../../constants/spec';
import {ResetBtn, SubmitBtn} from '../../components/md-button';
import {translate, withTranslation} from '../../services/translate.service';
import separateService from '../../services/separate-with-comma.service';

import {findHint, RichHintTitle} from "../../components/hints/hints";
import UserPermissionModel from "../../models/user-permission.model";

// config
// const selectStyles = {singleValue: provided => ({...provided, textAlign: 'left', color: 'black'})};

class Edit extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, hints } = this.props;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col style={{position: 'relative'}} xs={12} md={{span:10, offset:1}} lg={{span:8, offset:2}} >
                            <Paper className="indent-8 text-center">
                                <div>
                                <AccountBalance fontSize="large" className="align-middle"/>&nbsp;
                                <span className="align-middle"><RichHintTitle update={EDIT}  name={'ORGANIZATION$CYBER_INSURANCE_INFORMATION'} expectAnswer={expectAnswer}  data={findHint(hints, 'ORGANIZATION$CYBER_INSURANCE_INFORMATION')}/></span>
                                </div>
                                <ConnectedError />
                                <ConnectedForm />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.organizationCyberInsuranceInfo.expectAnswer, hints: state.organizationCyberInsuranceInfo.hintsData}),
    dispatch => ({
        initialize: () => dispatch({type: EDIT.INITIALIZE}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.organizationCyberInsuranceInfo.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.organizationCyberInsuranceInfo.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        disabled: state.organizationCyberInsuranceInfo.expectAnswer
            || !UserPermissionModel.checkUserPermission(state.app.user, PERMISSION.ORGANIZATION.UPDATE)
            || !UserPermissionModel.checkUserPermission(state.app.user, PERMISSION.ORGANIZATION.CYBER_INSURANCE_INFORMATION),
        formValues: getFormValues(FORM_NAME)(state),
        hints: state.organizationCyberInsuranceInfo.hintsData,
        initialValues: state.organizationCyberInsuranceInfo.data,
        user: state.app.user,
    }),
    dispatch => ({
        update: formData => dispatch({ type: EDIT.UPDATE, ...formData})
       // updateLogo:
    })
)( reduxForm({ 
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values) => {
        let errors = {};

        // currency
        /*
        if (!values.currency) {
            errors.currency = 'ORGANIZATION$CURRENCY_REQUIRED';
        }
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        */

        return errors;
    }

    })(
    ({ handleSubmit, update, invalid, disabled, reset, pristine, change, user, initialValues, formValues }) => {
        // console.log(RiskToleranceService.RATING_VALUES_SP().map(key=>({value: key, label: key})));
        return (<form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
            <Row className="offset-top-10">
                <Col sm={6}>
                    <h3 className="text-center text-highlighted">{translate('ORGANIZATION$CYBER_INSURANCE_LIMIT_AND_GENERAL')}</h3>
                    <Row className="offset-bottom-4">
                        <Col>
                            <Field
                                type="text"
                                component={Input}
                                name="insuranceLimit"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$INSURANCE_LIMIT')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col>
                            <Field
                                type="text"
                                component={Input}
                                name="insuranceDeductible"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$CYBER_INSURANCE_DEDUCTIBLE')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col>
                            <Field
                                type="text"
                                component={Input}
                                name="recordPriceLimit"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$RECORD_PRICE_LIMIT')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col>
                            <Field
                                type="text"
                                component={Input}
                                name="maximumWrittenPremium"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$MAXIMUM_WRITTEN_PREMIUM')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col>
                            <Field
                                type="text"
                                component={Input}
                                name="maximumCarrierLimit"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$MAXIMUM_CARRIER_LIMIT')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                    <h3 className="text-center text-highlighted">{translate('ORGANIZATION$CYBER_INSURANCE_SUBLIMITS')}</h3>
                    <Row className="offset-bottom-4">
                        <Col>
                            <Field
                                type="text"
                                component={Input}
                                name="ransomwareSublimit"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$RANSOMWARE_SUBLIMIT')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col>
                            <Field
                                type="text"
                                component={Input}
                                name="privacyGdprSublimit"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$PRIVACY_GDPR_SUBLIMIT')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col>
                            <Field
                                type="text"
                                component={Input}
                                name="privacyCcpaSublimit"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$PRIVACY_CCPA_SUBLIMIT')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col>
                            <Field
                                type="text"
                                component={Input}
                                name="ddosSublimit"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$DDOS_SUBLIMIT')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn
                        disabled={pristine || disabled || invalid}
                        permission={PERMISSION.ORGANIZATION.UPDATE}
                            />
                    <ResetBtn
                        onClick={reset}
                        className="offset-left-2"
                        disabled={pristine||disabled}
                        permission={PERMISSION.ORGANIZATION.UPDATE}
                            />
                </Col>
            </Row>
        </form>)
    }
)));
