// outsource dependencies
import {find, findIndex, get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {AccountBalance} from '@mui/icons-material';
import {Field, FieldArray, getFormValues, reduxForm} from 'redux-form';
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper} from '@mui/material';

// local dependencies
import {EDIT} from './actions';
import Input from '../../components/md-input';
import MdSwitch from '../../components/md-switch';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {instanceAPI} from '../../services/api.service';
import {MdAsyncSelect, MdSelect} from '../../components/md-select';
import SelectEntities from '../../components/select-entities';
import {ENTITY_TYPES, PERMISSION, THRESHOLD_RISK_LEVELS} from '../../constants/spec';
import {ResetBtn, SubmitBtn} from '../../components/md-button';
import PermissionAccess from '../../components/permission-access';
import {translate, withTranslation} from '../../services/translate.service';
import {selectImageSrc} from "../../images";

import {findHint, RichHintTitle} from '../../components/hints/hints';
import InputImage from "../../components/input-image";
import UserPermissionModel from "../../models/user-permission.model";
import separateWithCommaService from "../../services/separate-with-comma.service";

// config
const selectStyles = {singleValue: provided => ({...provided, textAlign: 'left', color: 'black'})};

class Edit extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, hints } = this.props;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col style={{position: 'relative'}} xs={12} md={{span:10, offset:1}} lg={{span:8, offset:2}} >
                            <Paper className="indent-8 text-center">
                                <div>
                                <AccountBalance fontSize="large" className="align-middle"/>&nbsp;
                                <span className="align-middle"> <RichHintTitle update={EDIT}  name={'ORGANIZATION$TITLE'} expectAnswer={expectAnswer}  data={findHint(hints, 'ORGANIZATION_TITLE')}/></span>
                                </div>
                                <ConnectedError />
                                <ConnectedForm />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.organization.expectAnswer, hints: state.organization.hintsData}),
    dispatch => ({
        initialize: () => dispatch({type: EDIT.INITIALIZE}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.organization.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.organization.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        disabled: state.organization.expectAnswer || !UserPermissionModel.checkUserPermission(state.app.user, PERMISSION.ORGANIZATION.UPDATE),
        formValues: getFormValues('editOrganization')(state),
        hints: state.organization.hintsData,
        initialValues: state.organization.data,
        user: state.app.user,
        hasEditPermission: UserPermissionModel.checkUserPermission(state.app.user, PERMISSION.ORGANIZATION.UPDATE),
    }),
    dispatch => ({
        update: formData => dispatch({ type: EDIT.UPDATE, ...formData})
       // updateLogo:
    })
)( reduxForm({ 
    form: 'editOrganization',
    enableReinitialize: true,
    validate: (values) => {
        let errors = {};

        console.log(values)
        // currency
        if (!values.currency) {
            errors.currency = 'ORGANIZATION$CURRENCY_REQUIRED';
        }
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        if (!values.site) {
            errors.site = 'ORGANIZATION$SITE_REQUIRED';
        }
        // industry
        if (!values.industry) {
            errors.industry = 'VENDORS$INDUSTRY_REQUIRED';
        }
        // Currency
        if (!values.currency) {
            errors.currency = 'ORGANIZATION$CURRENCY_REQUIRED';
        }
        // Country
        if (!values.country) {
            errors.country = 'ORGANIZATION$COUNTRY_REQUIRED';
        }
        // Address
        if (!values.streetAddress1) {
            errors.streetAddress1 = 'ORGANIZATION$ADDRESS_1_REQUIRED';
        }
        // language
        if (!values.language) {
            errors.language = 'ORGANIZATION$LANGUAGE_REQUIRED';
        }
        // Zip
        if (!values.zip) {
            errors.zip = 'ORGANIZATION$ZIP_REQUIRED';
        }
        // status
        if (!values.status) {
            errors.status = 'GLOBALS$STATUS_REQUIRED';
        }
        return errors;
    }

    })(
    ({ handleSubmit, update, invalid, disabled, reset, pristine, user, initialValues, formValues, hints}) => {
        // let isAllowed = (user.permissions || []).includes(PERMISSION.ORGANIZATION.UPDATE);
        console.log('Disabled: ', disabled);
        return (<form autoComplete="off" name="editOrganization" onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-4">
                <Col sm={3} className="organization-logo">
                    <Field
                        required
                        name="logoDocument"
                        placeholder="logo"
                        disabled={disabled}
                        url={initialValues.logo}
                        component={InputImage}
                        className="form-control"
                        onChange={update}
                        // label={<span> @ </span>}
                    />
                </Col>
                {/*<Col xs={3}/>*/}
                <Col sm={{span:9, offset:3}}>
                    <Field
                        name="name"
                        required
                        disabled={disabled}
                        component={Input}
                        style={{color: 'black'}}
                        placeholder={translate('GLOBALS$NAME')}
                        label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                            />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        disabled={disabled}
                        required
                        multiline={true}
                        name="description"
                        component={Input}
                        style={{color: 'black'}}
                        placeholder={translate('ORGANIZATION$DESCRIPTION')}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$DESCRIPTION')} </strong>)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className="offset-bottom-4">
                    <Field
                        name="site"
                        required
                        disabled={disabled}
                        component={Input}
                        style={{color: 'black'}}
                        placeholder={translate('ORGANIZATION$SITE')}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$SITE')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={4} className="offset-bottom-4">
                    <Field
                        type="tel"
                        name="phone"
                        disabled={disabled}
                        component={Input}
                        style={{color: 'black'}}
                        placeholder={translate('ORGANIZATION$SITE')}
                        label={(<strong> {translate('ORGANIZATION$PHONE_NUMBER')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={4} className="offset-bottom-4">
                    <Field
                        name="taxId"
                        disabled={disabled}
                        component={Input}
                        style={{color: 'black'}}
                        label={(<strong> {translate('ORGANIZATION$TAX_ID')} </strong>)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <SelectEntities
                        name="currency"
                        disabled={disabled}
                        styles={selectStyles}
                        type={ENTITY_TYPES.CURRENCIES}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$CURRENCY')} </strong>)}
                        getOptionLabel={option => `${get(option, 'name')} (${get(option, 'code')})`}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <SelectEntities
                        name="industry"
                        disabled={disabled}
                        styles={selectStyles}
                        type={ENTITY_TYPES.INDUSTRIES}
                        placeholder={translate('VENDORS$INDUSTRY')}
                         required={true}
                        label={(<strong className="required-asterisk"> {translate('VENDORS$INDUSTRY')} </strong>)}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        required
                        disabled={disabled}
                        component={Input}
                        name="streetAddress1"
                        style={{color: 'black'}}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$ADDRESS_1')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        disabled={disabled}
                        component={Input}
                        name="streetAddress2"
                        style={{color: 'black'}}
                        label={(<strong> {translate('ORGANIZATION$ADDRESS_2')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <SelectEntities
                        name="language"
                        valueKey="id"
                        labelKey="name"
                        disabled={disabled}
                        styles={selectStyles}
                        type={ENTITY_TYPES.LANGUAGES}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$LANGUAGE')} </strong>)}
                    />
                </Col>
            </Row>
            <LocationConnected disabled={disabled} />
            <Row>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        required
                        name="zip"
                        component={Input}
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$ZIP')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        name="vatId"
                        disabled={disabled}
                        component={Input}
                        style={{color: 'black'}}
                        label={(<strong> {translate('ORGANIZATION$VAT')} </strong>)}
                    />
                </Col>
                <Col xs={12} lg={4} className="offset-bottom-4">
                    <SelectEntities
                        name="status"
                        valueKey="id"
                        labelKey="name"
                        disabled={disabled}
                        styles={selectStyles}
                        type={ENTITY_TYPES.STATUSES}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('GLOBALS$STATUS')} </strong>)}
                            />
                </Col>

                <PermissionAccess permission={PERMISSION.ORGANIZATION.AVERAGE_REVENUE}>
                    <Col xs={12} lg={4} className="offset-bottom-4">
                        <Field
                            required
                            type="text"
                            component={Input}
                            name="averageRevenue"
                            style={{color: 'black'}}
                            disabled={disabled}
                            label={(<strong className="required-asterisk"> {translate('ORGANIZATION$ANNUAL_REVENUE')} </strong>)}
                            normalize={separateWithCommaService.normalize}
                        />
                    </Col>
                </PermissionAccess>

                <PermissionAccess permission={PERMISSION.ORGANIZATION.MARKET_CAPITALIZATION}>
                    <Col xs={12} lg={4} className="offset-bottom-4">
                        <Field
                            required = {formValues && formValues.isPublicCompany}
                            type="text"
                            component={Input}
                            disabled={disabled}
                            name="marketCapitalizationNumber"
                            placeholder={translate('ORGANIZATION$MARKET_CAPITALIZATION')}
                            label={(<strong className={formValues && formValues.isPublicCompany ? "required-asterisk" : ""}> {translate('ORGANIZATION$MARKET_CAPITALIZATION')} </strong>)}
                            normalize={separateWithCommaService.normalize}
                        />
                    </Col>
                </PermissionAccess>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        name="notes"
                        disabled={disabled}
                        multiline={true}
                        component={Input}
                        style={{color: 'black'}}
                        placeholder={translate('GLOBALS$NOTES')}
                        label={(<strong> {translate('GLOBALS$NOTES')} </strong>)}
                            />
                </Col>
            </Row>

            {/*
                <PermissionAccess permission={PERMISSION.ORGANIZATION.RECORD_PRICE_LIMIT}>
                    <Col xs={12} md={4} className="offset-bottom-4">
                        <Field
                            type="number"
                            component={Input}
                            name="recordPriceLimit"
                            style={{color: 'black'}}
                            disabled={disabled}
                            label={(<strong> {translate('ORGANIZATION$RECORD_PRICE_LIMIT')} </strong>)}
                        />
                    </Col>
                </PermissionAccess>

                <PermissionAccess permission={PERMISSION.ORGANIZATION.AGGREGATE_INSURANCE_LIMIT}>
                    <Col xs={12} md={4} className="offset-bottom-4">
                        <Field
                            type="text"
                            component={Input}
                            name="insuranceLimit"
                            style={{color: 'black'}}
                            disabled={disabled}
                            label={(<strong> {translate('ORGANIZATION$INSURANCE_LIMIT')} </strong>)}
                            normalize={separateService.normalize}
                        />
                    </Col>
                </PermissionAccess>

                <PermissionAccess permission={PERMISSION.ORGANIZATION.CYBER_INSURANCE_DEDUCTIBLE}>
                    <Col xs={12} md={4} className="offset-bottom-4">
                        <Field
                            type="text"
                            component={Input}
                            name="insuranceDeductible"
                            style={{color: 'black'}}
                            disabled={disabled}
                            label={(<strong> {translate('ORGANIZATION$CYBER_INSURANCE_DEDUCTIBLE')} </strong>)}
                            normalize={separateService.normalize}
                        />
                    </Col>
                </PermissionAccess>

            */}
            <Row className="offset-bottom-4">

                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        labelKey="label"
                        valueKey="value"
                        name="qualThreshold"
                        disabled={disabled}
                        component={MdSelect}
                        options={[1, 2, 3, 4, 5].map(option=>{
                            return ({
                                label: THRESHOLD_RISK_LEVELS[option] ,
                                value: option
                            })
                        })}
                        optionsLabel={({value}) => THRESHOLD_RISK_LEVELS[value]}
                        label={(<strong> {translate('ORGANIZATION$RISK_THRESHOLD')} </strong>)}
                        sendValueProp
                        simpleValue={(value) => {
                            return {
                                value: value,
                                label: value && THRESHOLD_RISK_LEVELS[value] ? THRESHOLD_RISK_LEVELS[value] : ''
                            }
                        }}
                    />
                </Col>


                <Col xs={6} md={3} className="text-center top-indent-2 offset-bottom-2">
                    <Field
                        fullWidth={false}
                        component={MdSwitch}
                        name="isPublicCompany"
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$PUBLIC_COMPANY')} </strong>)}
                            />
                </Col>
            </Row>
            <PermissionAccess permission={PERMISSION.ORGANIZATION.UPDATE_SUPPORTED_LANGUAGES}>
                <Row>
                    <Col xs={12}>
                        <FieldArray
                            disabled={disabled}
                            name="supportedLanguages"
                            component={CheckboxGroup}
                            label={(<strong> {translate('ORGANIZATION$SUPPORTED_LANGUAGES')} </strong>)}
                        />
                    </Col>
                </Row>
            </PermissionAccess>
            <Row>
                <Col xs={12} className="text-right">
                     <SubmitBtn disabled={pristine || disabled || invalid} permission={PERMISSION.ORGANIZATION.UPDATE} hint ={findHint(hints, 'BUTTON_ORGANIZATION_SAVE')} />
                    <ResetBtn onClick={reset} permission={PERMISSION.ORGANIZATION.UPDATE} className="offset-left-2" disabled={pristine||disabled} hint ={findHint(hints, 'BUTTON_ORGANIZATION_RESET')} />
                </Col>
            </Row>
        </form>)
    }
)));

const LocationConnected = withTranslation(connect(
    state => ({
        cities: state.organization.cities,
        states: state.organization.states,
        values: state.form.editOrganization.values
    }),
    dispatch => ({
        setupCountry: countryId => dispatch({ type: EDIT.SETUP_COUNTRY.REQUEST, countryId }),
        setupState: ( countryId, stateId ) => dispatch({ type: EDIT.SETUP_STATE, countryId, stateId }),
    })
)(({values, states, cities, setupCountry, setupState, disabled}) => (
    <Row>
        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
            <Field
                required
                name="country"
                valueKey="id"
                labelKey="name"
                disabled={disabled}
                styles={selectStyles}
                component={MdAsyncSelect}
                placeholder={translate('ORGANIZATION$COUNTRY')}
                label={(<strong className="required-asterisk"> {translate('ORGANIZATION$COUNTRY')} </strong>)}
                onChange={(e) => setupCountry(get(e, 'id', null))}
                loadOptions={(name, done) => {
                    instanceAPI({
                        method: 'post',
                        url: 'countries/filter',
                        data: { page: 0, size: 6, filter: { name } },
                    }).then(({items})=>done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
            <Field
                name="state"
                valueKey="id"
                labelKey="name"
                disabled={disabled}
                styles={selectStyles}
                defaultOptions={states}
                component={MdAsyncSelect}
                placeholder={translate('ORGANIZATION$STATE')}
                label={(<strong> {translate('ORGANIZATION$STATE')} </strong>)}
                onChange={(e) => setupState(get(values, 'country.id', null), get(e, 'id', null))}
                loadOptions={(name, done) => {
                    instanceAPI({
                        method: 'post',
                        url: 'states/filter',
                        data: { page: 0, size: 6, filter: { name, countryId: get(values, 'country.id', null) } },
                    }).then(({items})=>done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} lg={4} className="offset-bottom-4">
            <Field
                name="city"
                valueKey="id"
                labelKey="name"
                disabled={disabled}
                styles={selectStyles}
                defaultOptions={cities}
                component={MdAsyncSelect}
                placeholder={translate('ORGANIZATION$CITY')}
                label={(<strong> {translate('ORGANIZATION$CITY')} </strong>)}
                loadOptions={(name, done) => {
                    let filter = {
                        name,
                        stateId: get(values, 'state.id', null),
                        countryId: get(values, 'country.id', null),
                    };
                    instanceAPI({
                        method: 'post',
                        url: 'cities/filter',
                        data: { page: 0, size: 6, filter },
                    }).then(({items})=>done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
)));


const CheckboxGroup = connect(
    state => ({availableLanguages: state.organization.availableLanguages}),
    null
)( ({ fields, availableLanguages, label }) => {
    const allValues = fields.getAll();
    const toggle = (event, option = {}) => {
        const key = findIndex(allValues, {id: option.id});
        if (event.target.checked) fields.push(option);
        else key !== -1 && fields.remove(key);
    };
    return (<FormControl component="fieldset">
        <FormLabel component="legend"> {label} </FormLabel>
        <FormGroup>
            {availableLanguages.map((option = {}, key) => (
                <FormControlLabel
                    key={key}
                    label={<span><img alt={option.name} src={selectImageSrc(option.code)} height={25}/>{' - ' + option.name}</span>}
                    checked={Boolean(find(allValues, {id: option.id}))}
                    control={<Checkbox style={{padding: '6px 12px'}} value={option.name} onChange={event => toggle(event, option)} />}
                        />
            ))}
        </FormGroup>
    </FormControl>);
});

// const separateWithComma = value => {
//     if (!value) return value;
//     return Number(value.replace(/[^\d]/g, '')).toLocaleString('en')
// };
