// outsource dependencies
import {every, get} from 'lodash';
import {connect} from 'react-redux';
import React, {useEffect, useRef} from 'react';
// import { Prompt } from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {Accordion, AccordionDetails, AccordionSummary, Paper, Tab, Tabs} from '@mui/material';
import {Field, FieldArray, isPristine, reduxForm} from 'redux-form';

// local dependencies
import {EDIT, QUESTIONS} from '../actions';
import is from '../../../services/is.service';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {CategoryQuestions, QuestionRow} from '../../../components/question-row';
import {CancelBtn, ResetBtn, SubmitBtn, WarningBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {VENDORS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import getSystemName from '../../../helpers/getSystemName';
import {history} from '../../../store';
import {useParams} from 'react-router-dom';
import {getQueryStringParameter} from '../../../helpers/react-utils';
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import {ACTIONS} from "../../cybersecurity-maturity/actions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useReactToPrint} from "react-to-print";
import {useInView} from "react-intersection-observer";

const Questions = (props) => {

    let params = useParams();
    useEffect(() => {
        props.initialize(params);
    }, []);

    let {message, disabled, clearError, pristine, group, hints} = props;

    const vendorMap = {
        ...VENDORS_MAP,
        '/private/vendors/questions/scoring': {
            id: 14,
            title: `${props.data.name ? `${props.data.name} /` : ''} ${translate('VENDORS$SCORING_QUESTIONS')}  /  ${props.data.metricDomainCode ? getSystemName(props.data.metricDomainCode) : 'All'}`,
            parent: 11
        },
        '/private/vendors/questions/internal': {
            id: 15,
            title: `${props.data.name ? `${props.data.name} /` : ''} ${translate('VENDORS$INTERNAL_QUESTIONS')}  /  ${props.data.metricDomainCode ? getSystemName(props.data.metricDomainCode) : 'All'}`,
            parent: 11
        },
    };
    return (<div style={{height: '100%', overflowY: 'auto'}}>
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={vendorMap}/>
            <ConnectedInitializer>
                <Row className="offset-top-2">
                    <Col xs={12}>
                        <Row className="offset-bottom-4">
                            <Col xs={12} lg={pristine ? 12 : 8}>
                                <h2 className="text-uppercase">
                                    <RichHintTitle
                                        update={EDIT}
                                        name= {group === 'scoring' ? translate('VENDORS$SCORING_QUESTIONS') : translate('VENDORS$INTERNAL_QUESTIONS')}
                                        data={findHint(hints, group === 'scoring' ? `VENDORS_LEVEL_1_QUESTIONS_TITLE` : 'VENDORS_LEVEL_2_QUESTIONS_TITLE')}
                                    />
                                    <Preloader expectAnswer={disabled} type="ICON"/>
                                </h2>
                            </Col>
                            {!pristine && <Col xs={12} lg={4}>
                                <h3 className="text-danger text-right top-indent-1"><strong>
                                    <i className="fa fa-exclamation-triangle"
                                       aria-hidden="true"/> {translate('GLOBALS$UNSAVED_DATA')}
                                </strong></h3>
                            </Col>}
                        </Row>
                        <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col>
                        </Row>
                        <Row> <Col xs={12}> <ConnectedForm /> </Col> </Row>
                        {/*NOTE block navigating away from a page and show message*/}
                        {/*<Prompt when={!pristine} message={translate('GLOBALS$UNSAVED_DATA_MESSAGE')} />*/}
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    </div>);
};

export default connect(
    state => ({
        data: state.vendors.questions.data,
        group: state.vendors.questions.group,
        pristine: isPristine('scoringQuestions')(state),
        message: state.vendors.questions.errorMessage,
        disabled: state.vendors.questions.expectAnswer,
        hints: state.vendors.questions.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: QUESTIONS.CLEAR}),
        initialize: params => dispatch({type: QUESTIONS.INITIALIZE, ...params}),
        clearError: () => dispatch({type: QUESTIONS.META, errorMessage: null}),
    })
)(Questions);

const ConnectedInitializer = connect(
    state => ({initialize: state.vendors.questions.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        metric: state.vendors.questions.metric,
        initialValues: state.vendors.questions.data,
        calculations: null,
        // calculations: state.vendors.data.calculations,
        disabled: state.vendors.questions.expectAnswer,
        metricDomains: state.vendors.questions.metricDomains,
        questionsLength: get(state, 'vendors.questions.data.questions.length', 0),
        hints: state.vendors.questions.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: QUESTIONS.CANCEL}),
        update: formData => dispatch({type: QUESTIONS.UPDATE, ...formData}),
        changeMetric: metric => dispatch({type: QUESTIONS.UPDATE_METRIC, metric}),
        uploadFile: (file, fieldName) => dispatch({type: QUESTIONS.UPLOAD_FILE, file, fieldName}),
    })
)(reduxForm({
    form: 'scoringQuestions',
    enableReinitialize: true,
    warn: values => {
        const warnings = {};
        let map = {};
        // NOTE build question - answer map
        (values.questions || []).forEach(item => {
            map[item.id] = get(item, 'selectedAnswers.id', null);
        });
        // NOTE check branching logic
        let branchingWarns = [];
        (values.questions || []).forEach((item, index) => {
            let warns = {};
            let branchingLogic = get(item, 'branchingLogic', []);
            let isVisible = every(branchingLogic, branching => {
                const questionId = get(branching, 'question.id'),
                    answerId = get(branching, 'answer.id');
                return map[questionId] === answerId;
            });
            if (!isVisible) {
                warns.isHidden = true;
            }
            branchingWarns[index] = warns;
        });
        if (!is.empty(branchingWarns)) {
            warnings.questions = branchingWarns;
        }
        return warnings;
    }
})(({
    handleSubmit,
    invalid,
    pristine,
    disabled,
    update,
    reset,
    questionsLength,
    calculations,
    initialValues,
    uploadFile,
    changeMetric,
    metric,
    metricDomains,
    cancel,
    hints
}) => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true,
    });

    const [ref, inView] = useInView({triggerOnce: false, rootMargin: '0px 0px',});

    const back = getQueryStringParameter('back');

    return (
        <form autoComplete="off" name="scoringQuestions" onSubmit={handleSubmit(update)}>

            {!inView ? (
                <div className="page-save-block-hover">
                    <SubmitBtn disabled={pristine || invalid || disabled} className="offset-right-2" hint={findHint(hints, `BUTTON_QUESTIONS_SAVE`)} />
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint={findHint(hints, `BUTTON_QUESTIONS_RESET`)}/>
                    <CancelBtn onClick={cancel} hint={findHint(hints, `BUTTON_QUESTIONS_CANCEL`)} />
                </div>
            ) : ""}

            <Paper square>
                <Row>
                    <Col xs={10}>
                        <Tabs
                            value={metric}
                            textColor="primary"
                            variant="scrollable"
                            indicatorColor="primary"
                            onChange={(e, value) => changeMetric(value)}
                        >
                            {metricDomains.map((item, key) => <Tab key={key} value={item.code} label={item.name}/>)}
                        </Tabs>
                    </Col>
                    <Col xs={2}>
                        <div className="offset-top-2" style={{float: 'right', marginRight: 30}}>
                            <WarningBtn onClick={handlePrint} tooltip={"Print"} hint={findHint(hints, `BUTTON_QUESTIONS_PRINT`)} >
                                <i className="fa fa-print" aria-hidden="true"/>
                            </WarningBtn>
                            {back &&
                                <WarningBtn onClick={() => history.push(back)} tooltip={translate('GLOBALS$BACK')} hint={findHint(hints, `BUTTON_QUESTIONS_BACK`)} >
                                    <i className="fa fa-reply" aria-hidden="true"/>
                                </WarningBtn>
                            }
                        </div>
                    </Col>
                </Row>
            </Paper>
            <Paper square className="indent-5">
                <Container fluid>
                    <Row className="offset-bottom-4 printable-page" ref={componentRef}>
                        {calculations && (calculations && calculations[metric] && calculations[metric].hideCalculation ? "" : (
                            <Col xs={12} className="text-center text-uppercase offset-bottom-6">
                                <RichHintTitle classes="h3" placement="bottom" update={ACTIONS}  name={'Calculated value:'} expectAnswer={disabled} data={findHint(hints, 'CYBERSECURITY_MATURITY_CALCULATED_VALUE')}/>
                                <span className="h3 text-uppercase">
                                {(calculations && calculations[metric] && calculations[metric].resultNormalized
                                    ? Number(calculations[metric].resultNormalized * 100).toFixed(2) : "N/A")}
                            </span>
                            </Col>
                        ))}
                        <Col xs={12}>
                            {!questionsLength ? (
                                <h3 className="text-center text-uppercase offset-bottom-6"> {translate('GLOBALS$NO_QUESTIONS_FOUND')} </h3>
                            ) : questionsLength < 127 || initialValues.categories.length < 2 ? (
                                <FieldArray name="questions" component={Items} uploadFile={uploadFile}
                                            initialValues={initialValues} disabled={disabled}/>
                            ) : (
                                <ItemsInCategories uploadFile={uploadFile} disabled={disabled}/>
                            )}
                        </Col>
                    </Row>
                    <Row ref={ref}>
                        <Col xs={12} className="text-right">
                            <SubmitBtn disabled={pristine || invalid || disabled} className="offset-right-2" hint={findHint(hints, `BUTTON_QUESTIONS_SAVE`)} />
                            <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint={findHint(hints, `BUTTON_QUESTIONS_RESET`)}/>
                            <CancelBtn onClick={cancel} hint={findHint(hints, `BUTTON_QUESTIONS_CANCEL`)} />
                        </Col>
                    </Row>
                </Container>
            </Paper>
        </form>
    );
}
)));

/**
 * component for item of "questions" list
 *
 * @param {Object} props
 * @private
 */
const Items = ({fields, disabled, uploadFile}) => {
    return fields.map((mKey, index) => (
        <Field key={index} name={mKey} component={QuestionRow} disabled={disabled} uploadFile={uploadFile}/>));
};

/**
 * component for item of "questions" list in category
 *
 * @param {Object} props
 * @private
 */
const CategoryItems = ({fields, disabled, uploadFile, questionCategory}) => {
    return fields.map((mKey, index) => (
        <Field key={index} name={mKey} component={CategoryQuestions} category={questionCategory} disabled={disabled}
               uploadFile={uploadFile}/>));
};

const ItemsInCategories = withTranslation(connect(
    state => ({categories: state.cybersecurityMaturity.data.categories}),
)(({categories, disabled, uploadFile}) => (
    categories.map((item, index) => {
        return (
            <Accordion key={item.categoryName} defaultExpanded={(index === 0)} TransitionProps={{unmountOnExit: true}}>
                <AccordionSummary className="header-row-title" expandIcon={<ExpandMoreIcon sx={{color: '#FFFFFF'}} />}>
                    {`${item.categoryName}`}
                </AccordionSummary>
                <AccordionDetails>
                    <FieldArray name="questions" component={CategoryItems}
                                questionCategory={item.categoryName}
                                uploadFile={uploadFile}
                                disabled={disabled}/>
                </AccordionDetails>
            </Accordion>);
    })
)));
