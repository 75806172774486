// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {AccountBalance} from '@mui/icons-material';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Paper} from '@mui/material';

// local dependencies
import {EDIT} from './actions';
import {FORM_NAME} from './saga';
import Input from '../../components/md-input';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {PERMISSION} from '../../constants/spec';
import {ResetBtn, SubmitBtn} from '../../components/md-button';
import {translate, withTranslation} from '../../services/translate.service';
import separateService from '../../services/separate-with-comma.service';

import {findHint, RichHintTitle} from "../../components/hints/hints";
import UserPermissionModel from "../../models/user-permission.model";
import {RiskToleranceService} from "../../services/risk-tolerance-analysis.service.ja";
import {MdSelect} from "../../components/md-select";

// config
// const selectStyles = {singleValue: provided => ({...provided, textAlign: 'left', color: 'black'})};

class Edit extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, hints } = this.props;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col style={{position: 'relative'}} xs={12} md={{span:10, offset:1}} lg={{span:8, offset:2}} >
                            <Paper className="indent-8 text-center">
                                <div>
                                <AccountBalance fontSize="large" className="align-middle"/>&nbsp;
                                <span className="align-middle"><RichHintTitle update={EDIT}  name={'ORGANIZATION$CYBER_RISK_TOLERANCE'} expectAnswer={expectAnswer}  data={findHint(hints, 'ORGANIZATION$CYBER_RISK_TOLERANCE')}/></span>
                                </div>
                                <ConnectedError />
                                <ConnectedForm />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.organizationCyberRiskTolerance.expectAnswer, hints: state.organizationCyberRiskTolerance.hintsData}),
    dispatch => ({
        initialize: () => dispatch({type: EDIT.INITIALIZE}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.organizationCyberRiskTolerance.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.organizationCyberRiskTolerance.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        disabled: state.organizationCyberRiskTolerance.expectAnswer
            || !UserPermissionModel.checkUserPermission(state.app.user, PERMISSION.ORGANIZATION.UPDATE)
            || !UserPermissionModel.checkUserPermission(state.app.user, PERMISSION.ORGANIZATION.CYBER_INSURANCE_INFORMATION),
        formValues: getFormValues(FORM_NAME)(state),
        hints: state.organizationCyberRiskTolerance.hintsData,
        initialValues: state.organizationCyberRiskTolerance.data,
        user: state.app.user,
    }),
    dispatch => ({
        update: formData => dispatch({ type: EDIT.UPDATE, ...formData})
       // updateLogo:
    })
)( reduxForm({ 
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values) => {
        let errors = {};

        // currency
        /*
        if (!values.currency) {
            errors.currency = 'ORGANIZATION$CURRENCY_REQUIRED';
        }
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        */

        return errors;
    }

    })(
    ({ handleSubmit, update, invalid, disabled, reset, pristine, change, user, initialValues, formValues }) => {
        // console.log(RiskToleranceService.RATING_VALUES_SP().map(key=>({value: key, label: key})));
        return (<form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
            <Row className="offset-top-10">
                <Col sm={12}>
                    <Row className="offset-top-4">
                        <Col>
                            <h3 className="text-center text-highlighted">{translate('ORGANIZATION$NET_RISK_BEARING_CAPACITY')}</h3>
                        </Col>
                    </Row>

                    <Row className="offset-bottom-4">
                        <Col sm={6} className="offset-bottom-4">
                            <Field
                                type="text"
                                component={Input}
                                name="revenue"
                                style={{color: 'black'}}
                                disabled={disabled}
                                label={(<strong> {translate('ORGANIZATION$REVENUE')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                        <Col sm={6} className="offset-bottom-4">
                            <Field
                                type="text"
                                component={Input}
                                name="ebitda"
                                style={{color: 'black'}}
                                disabled={disabled}
                                onChange={(event) => {
                                    if (event && event.target && event.target.value) {
                                        let grossRiskBearingCapacity = RiskToleranceService.calculateGrossRiskBearingCapacity(event.target.value, formValues['creditRating'], formValues['debt'], formValues['pensionDebt']);
                                        change('grossRiskBearingCapacity', separateService.normalizeReal(String(grossRiskBearingCapacity)));
                                        change('cyberRiskBearingCapacity', separateService.normalizeReal(String(RiskToleranceService.calculateCyberRiskBearingCapacity(grossRiskBearingCapacity, formValues['businessRiskBearingCapacity']))));
                                    }
                                }}
                                label={(<strong> {translate('ORGANIZATION$EBITDA')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                        <Col sm={6} className="offset-bottom-4">
                            <Field
                                type="text"
                                component={Input}
                                name="debt"
                                style={{color: 'black'}}
                                disabled={disabled}
                                onChange={(event) => {
                                    if (event && event.target && event.target.value) {
                                        let grossRiskBearingCapacity = RiskToleranceService.calculateGrossRiskBearingCapacity(formValues['ebitda'], formValues['creditRating'], event.target.value, formValues['pensionDebt']);
                                        change('grossRiskBearingCapacity', separateService.normalizeReal(String(grossRiskBearingCapacity)));
                                        change('cyberRiskBearingCapacity', separateService.normalizeReal(String(RiskToleranceService.calculateCyberRiskBearingCapacity(grossRiskBearingCapacity, formValues['businessRiskBearingCapacity']))));
                                    }
                                }}
                                label={(<strong> {translate('ORGANIZATION$DEBT')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                        <Col sm={6} className="offset-bottom-4">
                            <Field
                                type="text"
                                component={Input}
                                name="pensionDebt"
                                style={{color: 'black'}}
                                disabled={disabled}
                                onChange={(event) => {
                                    if (event && event.target && event.target.value) {
                                        let grossRiskBearingCapacity = RiskToleranceService.calculateGrossRiskBearingCapacity(formValues['ebitda'], formValues['creditRating'], formValues['debt'], event.target.value);
                                        change('grossRiskBearingCapacity', separateService.normalizeReal(String(grossRiskBearingCapacity)));
                                        change('cyberRiskBearingCapacity', separateService.normalizeReal(String(RiskToleranceService.calculateCyberRiskBearingCapacity(grossRiskBearingCapacity, formValues['businessRiskBearingCapacity']))));
                                    }
                                }}
                                label={(<strong> {translate('ORGANIZATION$PENSION_DEBT')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                        <Col sm={6} className="offset-bottom-4">
                            <Field
                                valueKey="value"
                                labelKey="label"
                                component={MdSelect}
                                sendValueProp
                                name="creditRating"
                                style={{color: 'black'}}
                                disabled={disabled}
                                onChange={(event, value) => {
                                    if (value) {
                                        let grossRiskBearingCapacity = RiskToleranceService.calculateGrossRiskBearingCapacity(formValues['ebitda'], value, formValues['debt'], formValues['pensionDebt']);
                                        change('grossRiskBearingCapacity', separateService.normalizeReal(String(grossRiskBearingCapacity)));
                                        change('cyberRiskBearingCapacity', separateService.normalizeReal(String(RiskToleranceService.calculateCyberRiskBearingCapacity(grossRiskBearingCapacity, formValues['businessRiskBearingCapacity']))));
                                    }
                                }}
                                placeholder={translate('ORGANIZATION$CREDIT_RATING')}
                                label={(<strong> {translate('ORGANIZATION$CREDIT_RATING')} </strong>)}
                                options={RiskToleranceService.RATING_VALUES_SP().map(key=>({value: key, label: key}))}
                                optionsLabel={({value}) => value ? value : '' }
                                required={false}
                                simpleValue={(value) => { return {value: value, label: value} }}
                            />
                        </Col>
                        <Col sm={6} className="offset-bottom-4">
                            <Field
                                type="text"
                                component={Input}
                                name="grossRiskBearingCapacity"
                                style={{color: 'black'}}
                                disabled={true}
                                label={(<strong> {translate('ORGANIZATION$GROSS_RISK_BEARING_CAPACITY')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                        <Col sm={6} className="offset-bottom-4">
                            <Field
                                type="text"
                                component={Input}
                                name="businessRiskBearingCapacity"
                                style={{color: 'black'}}
                                disabled={disabled}
                                onChange={(event) => {
                                    if (event && event.target && event.target.value) {
                                        change('cyberRiskBearingCapacity', separateService.normalizeReal(String(RiskToleranceService.calculateCyberRiskBearingCapacity(formValues['grossRiskBearingCapacity'], event.target.value))));
                                    }
                                }}
                                label={(<strong> {translate('ORGANIZATION$BUSINESS_RISK_BEARING_CAPACITY')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                        <Col sm={6} className="offset-bottom-4">
                            <Field
                                type="text"
                                component={Input}
                                name="cyberRiskBearingCapacity"
                                style={{color: 'black'}}
                                disabled={true}
                                label={(<strong> {translate('ORGANIZATION$CYBER_RISK_BEARING_CAPACITY')} </strong>)}
                                normalize={separateService.normalizeReal}
                            />
                        </Col>
                    </Row>
                </Col>

            </Row>

            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn
                        disabled={pristine || disabled || invalid}
                        permission={PERMISSION.ORGANIZATION.UPDATE}
                            />
                    <ResetBtn
                        onClick={reset}
                        className="offset-left-2"
                        disabled={pristine||disabled}
                        permission={PERMISSION.ORGANIZATION.UPDATE}
                            />
                </Col>
            </Row>
        </form>)
    }
)));
