
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { put, call, select, takeEvery } from 'redux-saga/effects';

// local dependencies
import ACTIONS from './actions';
import { instanceAPI } from '../../services/api.service';
import { translate } from '../../services/translate.service';
import getHintsList, { GDPR_ARTICLES_IMPORT_EXPORT_HINTS } from '../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(ACTIONS.UPLOAD_FILE, uploadFileSaga);
    yield takeEvery(ACTIONS.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, GDPR_ARTICLES_IMPORT_EXPORT_HINTS);

        // NOTE setup hints data
        yield put({type: ACTIONS.META, hintsData});
    } catch ( {message} ) {
        yield put({type: ACTIONS.META, errorMessage: message});
    }
    yield put({type: ACTIONS.GET_HINTS_DATA.FINISH});
}

function* uploadFileSaga ({file}) {
    yield put({type: ACTIONS.META, expectAnswer: true, errorMessage: null });
    // NOTE clean previous data
    yield put({type: ACTIONS.DATA, data: {} });
    // NOTE get current risk model
    const riskModel = yield select(state => state.app.riskModel);
    try {
        let data = yield call(uploadFile, file, riskModel);
        yield put({type: ACTIONS.DATA, data});
        yield call(toastr.success, translate('IMPORT_AND_EXPORT$DATA_IMPORT'), translate('GLOBALS$SUCCESSFUL_FILE_UPLOAD'));
        yield put({type: ACTIONS.META, expectAnswer: false});
    }
    catch ({message}) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: ACTIONS.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * upload file
 * @param {File} file
 * @param {Object} riskModel
 * @private
 */
function uploadFile ( file, riskModel ) {
    const data = new FormData();
    data.append('file', file);
    return instanceAPI({method: 'post', url: `/data-import/csv/gdpr-articles/import?riskModelId=${riskModel.id}`, data});
}
