// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import React, {Fragment, useEffect} from 'react';
import {IconButton, InputLabel, Paper, Tooltip} from '@mui/material';
import {CloudDownload} from '@mui/icons-material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, FieldArray, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT, LIST} from '../actions';
import {DOWNLOAD} from '../../../constants/routes';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import MdDatePicker from '../../../components/md-date-picker';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import withDownloadLink from '../../../components/download-link';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {formatFileSize} from '../../../services/data-formatting.service';
import {translate, withTranslation} from '../../../services/translate.service';
import {AddIconBtn, CancelBtn, DeleteIconBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {POLICIES_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {useParams} from 'react-router-dom';
import Input from '../../../components/md-input';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// config
export const FORM_NAME = 'editPolicy';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = id === NEW_ID;
    let {message, clearError, hints, disabled} = props;
    return (<Container fluid>
        <Breadcrumbs breadCrumbsMap={POLICIES_MAP}/>
        <ConnectedInitializer>
            <Row className="offset-top-10">
                <Col xs={12} md={{span: 10, offset: 1}} lg={{span: 8, offset: 2}}>
                    <Paper className="indent-5">
                        <h2 className="text-uppercase">
                                <span>
                                    <RichHintTitle
                                        update={LIST}
                                        name={isNew ? translate('POLICY_MANAGEMENT$CREATE_POLICY') : translate('POLICY_MANAGEMENT$EDIT_POLICY')}
                                        expectAnswer={disabled}
                                        data={findHint(hints, 'POLICY_MANAGEMENT_TITLE')}/>

                                </span>
                            <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                        </h2>
                        <ErrorMessage active message={message} onChange={clearError}/>
                        <ConnectedForm isNew={isNew}/>
                    </Paper>
                </Col>
            </Row>
        </ConnectedInitializer>
    </Container>);
};

export default connect(
    state => ({
        data: state.policies.edit.data,
        message: state.policies.edit.errorMessage,
        expectAnswer: state.policies.edit.expectAnswer,
        hints: state.policies.edit.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.policies.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const DownloadLink = withTranslation(withDownloadLink()(props => (
    <Tooltip title={translate('GLOBALS$DOWNLOAD')} className="offset-bottom-2"><span>
        <IconButton {...props} color="primary" style={{padding: '5px'}} aria-label={translate('GLOBALS$DOWNLOAD')}>
            <CloudDownload fontSize="small"/>
        </IconButton>
    </span></Tooltip>
)));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.policies.edit.data,
        hints: state.policies.edit.hintsData,
        disabled: state.policies.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        removeDocument: () => dispatch(changeField('document', null)),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!(values.name)) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        if (!(values.version)) {
            errors.version = 'GLOBALS$VERSION_REQUIRED';
        }
        if (!(values.approvedBy)) {
            errors.approvedBy = 'POLICY_MANAGEMENT$APPROVED_BY_REQUIRED';
        }
        return errors;
    }
})(({
    handleSubmit,
    hints,
    invalid,
    pristine,
    disabled,
    update,
    reset,
    formValues = {},
    removeDocument,
    isNew,
    cancel
}) => (
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('POLICY_MANAGEMENT$POLICY_NAME')}
                    required={true}
                    label={(
                        <strong className="required-asterisk"> {translate('POLICY_MANAGEMENT$POLICY_NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    required
                    name="version"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$VERSION')}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$VERSION')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    name="approvedBy"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    getOptionLabel={option => get(option, 'fullName')}
                    placeholder={translate('POLICY_MANAGEMENT$APPROVED_BY')}
                    label={(
                        <strong className="required-asterisk"> {translate('POLICY_MANAGEMENT$APPROVED_BY')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="approvedAt"
                    disabled={disabled}
                    component={MdDatePicker}
                    label={(<strong> {translate('POLICY_MANAGEMENT$APPROVED')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    disabled={disabled}
                    name="annualReviewDate"
                    component={MdDatePicker}
                    label={(<strong> {translate('POLICY_MANAGEMENT$ANNUAL_REVIEW_DATE')} </strong>)}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="overview"
                    disabled={disabled}
                    multiline={true}
                    component={Input}
                    style={{color: 'black'}}
                    placeholder={translate('POLICY_MANAGEMENT$OVERVIEW')}
                    label={(<strong> {translate('POLICY_MANAGEMENT$OVERVIEW')} </strong>)}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="purpose"
                    disabled={disabled}
                    multiline={true}
                    component={Input}
                    style={{color: 'black'}}
                    placeholder={translate('POLICY_MANAGEMENT$PURPOSE')}
                    label={(<strong> {translate('POLICY_MANAGEMENT$PURPOSE')} </strong>)}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="scope"
                    disabled={disabled}
                    multiline={true}
                    component={Input}
                    style={{color: 'black'}}
                    placeholder={translate('POLICY_MANAGEMENT$SCOPE')}
                    label={(<strong> {translate('POLICY_MANAGEMENT$SCOPE')} </strong>)}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="rolesAndResponsibilities"
                    type={ENTITY_TYPES.CYBER_ROLES}
                    placeholder={translate('POLICY_MANAGEMENT$ROLES_AND_RESPONSIBILITIES')}
                    label={(<strong> {translate('POLICY_MANAGEMENT$ROLES_AND_RESPONSIBILITIES')} </strong>)}
                    getOptionLabel={option => `${get(option, 'name', '')}`}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="enforcement"
                    disabled={disabled}
                    multiline={true}
                    component={Input}
                    style={{color: 'black'}}
                    placeholder={translate('POLICY_MANAGEMENT$ENFORCEMENT')}
                    label={(<strong> {translate('POLICY_MANAGEMENT$ENFORCEMENT')} </strong>)}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="exceptions"
                    disabled={disabled}
                    multiline={true}
                    component={Input}
                    style={{color: 'black'}}
                    placeholder={translate('POLICY_MANAGEMENT$EXCEPTIONS')}
                    label={(<strong> {translate('POLICY_MANAGEMENT$EXCEPTIONS')} </strong>)}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="definitions"
                    disabled={disabled}
                    multiline={true}
                    component={Input}
                    style={{color: 'black'}}
                    placeholder={translate('POLICY_MANAGEMENT$DEFINITIONS')}
                    label={(<strong> {translate('POLICY_MANAGEMENT$DEFINITIONS')} </strong>)}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="relatedPolicies"
                    type={ENTITY_TYPES.POLICIES}
                    placeholder={translate('POLICY_MANAGEMENT$RELATED_POLICIES')}
                    label={(<strong> {translate('POLICY_MANAGEMENT$RELATED_POLICIES')} </strong>)}
                    getOptionLabel={option => `${get(option, 'name', '')}` + (option && option.version ? ` (${option.version})` : '')}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <FieldArray name="statements" rerenderOnEveryChange={true} component={StatementsItems}/>
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <h4>
                    <strong>{translate('GLOBALS$DOCUMENT_COLON')} </strong>
                    {
                        get(formValues, 'document') && (
                            <div>
                                <span>
                                    {get(formValues, 'document.fileName')} ({formatFileSize(get(formValues, 'document.fileSize'))})&nbsp;
                                        {get(formValues, 'document.downloadUrl') && (
                                            <DownloadLink className="offset-bottom-1 offset-left-2"
                                                link={() => DOWNLOAD.EVIDENCE_DOCUMENT_LINK({downloadUrl: get(formValues, 'document.downloadUrl')})}/>
                                        )}
                                        &nbsp;<DeleteIconBtn className="offset-bottom-1" style={{padding: '5px'}}
                                                             onClick={() => removeDocument()}/>
                                </span>
                                <div>
                                    <Document file={get(formValues, 'document.downloadUrl')}>
                                        <Page pageNumber={1} />
                                    </Document>
                                </div>
                            </div>
                        )
                    }
                </h4>
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12} className="dropzone">
                <UploadFile/>
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                hint={findHint(hints, isNew ? 'BUTTON_POLICY_MANAGEMENT_CREATE' : 'BUTTON_POLICY_MANAGEMENT_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_POLICY_MANAGEMENT_RESET')}/>
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_POLICY_MANAGEMENT_CANCEL')}/>
            </Col>
        </Row>
    </form>
))));


/**
 *
 * @param { Object } props
 * @public
 */
const StatementsItems = connect(
    state => ({disabled: state.policies.edit.expectAnswer}),
    null,
)(({fields, meta, disabled}) => (<Fragment>
    <Row>
        <Col xs={12}>
            <h3 className="text-uppercase">
                <span className="align-middle"> {translate('POLICY_MANAGEMENT$STATEMENTS')} </span>&nbsp;
                <AddIconBtn onClick={() => fields.push({})}/>
            </h3>
        </Col>
    </Row>
    {meta.error && (<Row> <Col xs={{span: 10, offset: 1}} className="text-center is-invalid">
        <label className="form-text h4"> {translate(meta.error)} </label>
    </Col> </Row>)}
    {fields.map((mKey, index) => (
        <Paper key={index} className="indent-4 offset-bottom-4">
            <Row className="offset-bottom-6">
                <Col xs={12} className="row-adornment">
                    <Field
                        name={`${mKey}.statement`}
                        disabled={disabled}
                        multiline={true}
                        component={Input}
                        style={{color: 'black'}}
                        placeholder={translate('POLICY_MANAGEMENT$STATEMENT')}
                        label={(<strong> {translate('POLICY_MANAGEMENT$STATEMENT')} </strong>)}
                    />
                    <div className="adornment">
                        <DeleteIconBtn onClick={() => fields.remove(index)}/>
                    </div>
                </Col>
                <Col xs={12} className="row-adornment">
                    <SelectEntities
                        isMulti
                        disabled={disabled}
                        name={`${mKey}.securityRequirements`}
                        type={ENTITY_TYPES.SECURITY_REQUIREMENTS}
                        placeholder={translate('SECURITY_REQUIREMENTS$TITLE')}
                        label={(<strong> {translate('SECURITY_REQUIREMENTS$TITLE')} </strong>)}
                        getOptionLabel={option => `${get(option, 'code', '')}.${get(option, 'securityControlName.name', '')}`}
                    />
                </Col>
            </Row>
        </Paper>
    ))}
</Fragment>));

const UploadFile = withTranslation(connect(
    state => ({
        uploaded: state.policies.edit.uploaded,
        disabled: state.policies.edit.expectAnswer
    }),
    dispatch => ({uploadFile: file => dispatch({type: EDIT.UPLOAD_FILE, file})})
)(({uploadFile, uploaded, disabled, ...attr}) => (<div>
    <InputLabel htmlFor="fileUpload"> <strong> {translate('GLOBALS$DOCUMENT_UPLOAD')} </strong> </InputLabel>
    <Dropzone
        {...attr}
        id="fileUpload"
        className="dropzone"
        useFsAccessApi={false}
        disabled={!uploaded || disabled}
        activeClassName="dropzone-active"
        disabledClassName="dropzone-disabled"
        accept={{
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xlsx', '.xls'],
            'application/vnd.ms-word': ['.docx', '.doc'],
            'application/pdf': ['.pdf'],
        }}
        onDrop={(acceptedFiles, rejectedFiles) => {
            if (!rejectedFiles.length) {
                return uploadFile(acceptedFiles[0]);
            }
        }}
    >
        {({rejectedFiles, getRootProps, getInputProps}) => {
            if (!uploaded) {
                return (<h3 className="text-center text-muted">{translate('GLOBALS$LOADING')}</h3>);
            }
            return (<div {...getRootProps()} className="text-center text-muted">
                <input {...getInputProps()} />
                <p style={{fontSize: '50px'}}><i className="fa fa-upload"/></p>
                <h3 className="offset-top-0">{translate('DROPZONE$DEFAULT_MESSAGE')}</h3>
            </div>);
        }}
    </Dropzone>
</div>)));
