// outsource dependencies
import {change} from 'redux-form';
import {toastr} from 'react-redux-toastr';
import {cloneDeep, get} from 'lodash';
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {ACTIONS} from './actions';
import {history, store} from '../../store';
import {instanceAPI} from '../../services/api.service';
import {translate} from '../../services/translate.service';
import {ORGANIZATION_RISK_SCORING} from '../../constants/routes';
import queryService from "../../services/query.service";
import getHintsList, {CYBERSECURITY_MATURITY_HINTS} from '../../services/hints.service';


// config
const metricAll = {id: 0, name: 'All', code: 'all'};

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(ACTIONS.GET_DATA, getDataSaga);
    yield takeEvery(ACTIONS.UPDATE, updateDataSaga);
    yield takeEvery(ACTIONS.INITIALIZE, initializeSaga);
    yield takeEvery(ACTIONS.UPLOAD_FILE, uploadFileSaga);
    yield takeEvery(ACTIONS.GET_HINTS_DATA.REQUEST, getHintsDataSaga);

    // NOTE setup listener on location change to listen history back event (POP)
    yield call(history.listen, historyBackListen);
}

function historyBackListen ({location : {pathname}}, action) {
    if ( action !== 'POP' || pathname !== ORGANIZATION_RISK_SCORING.ROUTE ) return;
    // NOTE reinitialize search from query string after the page path was changed
    // NOTE this event will fired before the url was changed
    setTimeout(()=>store.dispatch({type: ACTIONS.INITIALIZE}), 0);
}

function* initializeSaga ({subPage}) {
    yield put({type: ACTIONS.CLEAR});

    let params = yield call(queryService.parse, history.location.search);
    let { riskModel } = yield select( state => state.app );
    const metricDomains = yield call(getMetricDomains, riskModel.id, subPage);

    // NOTE take 'metric' initial param
    let { metric } = yield select( state => state.cybersecurityMaturity );
    let domainSearchResult = metricDomains.filter((element, index) => element.code === metric);
    if (!domainSearchResult || !domainSearchResult.length) {
        metric = metricDomains[1] ? metricDomains[1].code : "all";

        if (metricDomains.length === 2) {
            params.pageTitle = metricDomains[1].name;
        } else if (subPage) {
            params.pageTitle = subPage;
        } else {
            params.pageTitle = 'CYBERSECURITY_MATURITY$TITLE';
        }
    }

    yield put({type: ACTIONS.META, metricDomains, ...params});

    yield call(getDataSaga, {metric});
    yield put({type: ACTIONS.GET_HINTS_DATA.REQUEST});
    yield put({type: ACTIONS.META, initialized: true});
}

function* getDataSaga ({metric}) {
    yield put({type: ACTIONS.META, expectAnswer: true, errorMessage: null});
    try {
        let { riskModel } = yield select( state => state.app );
        let data = yield call(getData, riskModel.id, metric);
        data.calculations = yield call(getMetricsCalculations, riskModel.id);

        let categories = [];
        let categoriesMap = {};
        if (data.questions && data.questions.length > 0) {
            for (let i = 0; i < data.questions.length; i++) {
                let question = data.questions[i];
                let categoryName = (question.categoryName && question.categoryName.trim()) ? question.categoryName : 'Uncategorized';
                if (!categoriesMap[categoryName]) {
                    let items = [];
                    let categoryData = {categoryName: categoryName, questions: items};
                    categories.push(categoryData);
                    categoriesMap[categoryName] = items;
                }

                categoriesMap[categoryName].push(question);
            }

            if (categories.length > 1) {
                let overrideQuestions = [];
                for (let i = 0; i < categories.length; i++) {
                    let categoryData = categories[i];
                    for (let j = 0; j < categoryData.questions.length; j++) {
                        if (j === 0) {
                            categoryData.questions[j].questionCategory = categoryData.categoryName;
                        }
                        overrideQuestions.push(categoryData.questions[j]);
                    }
                }
                data.questions = overrideQuestions;
            }
        }

        data.categories = categories;

        // NOTE: setup data
        yield put({type: ACTIONS.DATA, data});
        yield put({type: ACTIONS.META, metric});
        // NOTE update location
        // yield call(updateLocation, {metric});
    }
    catch ({message}) {
        yield put({type: ACTIONS.META, errorMessage: message});
    }
    yield put({type: ACTIONS.META, expectAnswer: false});
}


/**
 * get metric domains
 * @private
 */
function getMetricDomains (riskModelId, subPage) {
    return instanceAPI({method: 'get', url: `/metric-domains/list/risk-model/${riskModelId}/Organization` + (subPage ? `?area=${subPage}` : '')}).then(success=>{success.unshift(metricAll); return success;});
    // return instanceAPI({method: 'get', url: '/metric-domains/filtered'}).then(success=>{success.unshift(metricAll); return success;})
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: ACTIONS.META, expectAnswer: true});
    try {
        let {riskModel} = yield select(state => state.app);
        yield call(updateData, riskModel.id, options);
        let { metric } = yield select( state => state.cybersecurityMaturity );
        yield call(getDataSaga, {metric});
        yield put({type: ACTIONS.META, expectAnswer: false});
        yield call(toastr.success, translate('CYBERSECURITY_MATURITY$TITLE'), translate('GLOBALS$SUCCESSFUL_DATA_UPDATE'));
    } catch ({message}) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: ACTIONS.META, expectAnswer: false, errorMessage: message});
    }
}

function* getHintsDataSaga () {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, CYBERSECURITY_MATURITY_HINTS);

        // NOTE setup hints data
        yield put({type: ACTIONS.META, hintsData});
    } catch ( {message} ) {
        yield put({type: ACTIONS.META, errorMessage: message});
    }
    yield put({type: ACTIONS.GET_HINTS_DATA.FINISH});
}

function* uploadFileSaga ({file, fieldName}) {
    yield put({type: ACTIONS.META, expectAnswer: true, errorMessage: null});
    // NOTE clear field
    yield put(change('scoringQuestions', fieldName, null));
    try {
        let document = yield call(uploadFile, file);
        yield call(toastr.success, translate('CYBERSECURITY_MATURITY$TITLE'), translate('GLOBALS$SUCCESSFUL_FILE_UPLOAD'));
        yield put(change('scoringQuestions', fieldName, document));
    } catch ({message}) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: ACTIONS.META, errorMessage: message});
    }
    yield put({type: ACTIONS.META, expectAnswer: false});
}

/**
 * get data by system id
 * @param {Number} riskModelId
 * @param {String} metricDomain
 * @private
 */
function getData( riskModelId, metricDomain ) {
    return instanceAPI({
        method: 'post',
        data: {metricDomain: metricDomain === 'all' ? null : metricDomain},
        url: `/risk-model/${riskModelId}/qualitative-questions/organization-filter`
    }).then(success=>prepareData(success));
}

/**
 * get data by system id
 * @param {Number} riskModelId
 * @private
 */
function getMetricsCalculations(riskModelId/*, metricDomain */) {
    return instanceAPI({
        method: 'get',
        url: `/risk-models/${riskModelId}/qual-metrics/calculate/organization`
    }).then(success => success);
}


/**
 * update data
 * @param {Number} riskModelId
 * @param {Object} data
 * @private
 */
function updateData( riskModelId, data ) {
    // NOTE convert 'selectedAnswers' property to array if value is not empty
    let preparedData = cloneDeep(data);
    (preparedData.questions || []).forEach(item => item.selectedAnswers = item.selectedAnswers && [item.selectedAnswers]);
    return instanceAPI({
        method: 'post',
        data: preparedData,
        url: `risk-model/${riskModelId}/qualitative-questions/organization-save/${preparedData.metricDomainCode}`
    }).then(success=>prepareData(success));
}

/**
 * prepare data for view
 * @param {Object} data
 * @private
 */
function prepareData( data ) {
    (data.questions||[]).forEach(item => {
        // NOTE convert 'selectedAnswers' property to object if selectedAnswers is not empty
        item.selectedAnswers = get(item, 'selectedAnswers[0]', null);
        // NOTE sort answers by answer weight
        (item.answers || []).sort((a, b) => get(a, 'answerWeight.value') - get(b, 'answerWeight.value'));
    });
    return data;
}

/**
 * upload file
 * @param {File} file
 * @private
 */
function uploadFile ( file ) {
    const data = new FormData();
    data.append('file', file);
    return instanceAPI({method: 'post', url: '/documents/question_answer/upload', data});
}
