
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const LIST = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        DELETE_ITEM: `${prefix}DELETE_ITEM`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@vendors/list/');

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
        CANCEL: `${prefix}CANCEL`,
        INITIALIZE: `${prefix}INITIALIZE`,
        SETUP_STATE: `${prefix}SETUP_STATE`,
        SETUP_TECHNOLOGY: `${prefix}SETUP_TECHNOLOGY`,
        SETUP_COUNTRY: createTypes(`${prefix}SETUP_COUNTRY`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@vendors/edit/');

export const QUESTIONS = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
        CANCEL: `${prefix}CANCEL`,
        GET_DATA: `${prefix}GET_DATA`,
        INITIALIZE: `${prefix}INITIALIZE`,
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
        UPDATE_METRIC: `${prefix}UPDATE_METRIC`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@vendors/questions/');
