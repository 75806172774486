
// outsource dependencies
import { change } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { get, find, cloneDeep } from 'lodash';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { initial } from './reducer';
import { ACTIONS } from './actions';
import { store, history } from '../../store';
import queryService from '../../services/query.service';
import { instanceAPI } from '../../services/api.service';
import { translate } from '../../services/translate.service';
import { ORGANIZATION_RISK_SCORING } from '../../constants/routes';

// config
const metricAll = {id: 0, name: 'All', code: 'all'};

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(ACTIONS.GET_DATA, getDataSaga);
    yield takeEvery(ACTIONS.UPDATE, updateDataSaga);
    yield takeEvery(ACTIONS.INITIALIZE, initializeSaga);
    yield takeEvery(ACTIONS.UPLOAD_FILE, uploadFileSaga);

    // NOTE setup listener on location change to listen history back event (POP)
    yield call(history.listen, historyBackListen);
}

function historyBackListen ({location : {pathname}}, action) {
    if ( action !== 'POP' || pathname !== ORGANIZATION_RISK_SCORING.ROUTE ) return;
    // NOTE reinitialize search from query string after the page path was changed
    // NOTE this event will fired before the url was changed
    setTimeout(()=>store.dispatch({type: ACTIONS.INITIALIZE}), 0);
}

function* initializeSaga () {
    yield put({type: ACTIONS.CLEAR});
    // NOTE take 'metric' param
    let { metric } = yield call(queryService.parse, history.location.search);
    const metricDomains = yield call(getMetricDomains);
    // NOTE validate 'metric' param
    metric = find(metricDomains, {code: metric}) ? metric : 'all';
    yield put({type: ACTIONS.META, metricDomains});
    yield call(getDataSaga, {metric});
    yield put({type: ACTIONS.META, initialized: true});
}

function* getDataSaga ({metric}) {
    yield put({type: ACTIONS.META, expectAnswer: true, errorMessage: null});
    try {
        let { riskModel } = yield select( state => state.app );
        let data = yield call(getData, riskModel.id, metric);
        // NOTE: setup data
        yield put({type: ACTIONS.DATA, data});
        yield put({type: ACTIONS.META, metric});
        // NOTE update location
        yield call(updateLocation, {metric});
    }
    catch ({message}) {
        yield put({type: ACTIONS.META, errorMessage: message});
    }
    yield put({type: ACTIONS.META, expectAnswer: false});
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: ACTIONS.META, expectAnswer: true});
    try {
        let { riskModel } = yield select( state => state.app );
        let data = yield call(updateData, riskModel.id, options);
        yield put({type: ACTIONS.DATA, data});
        yield put({type: ACTIONS.META, expectAnswer: false});
        yield call(toastr.success, translate('SCORING_QUESTIONS$TITLE'), translate('GLOBALS$SUCCESSFUL_DATA_UPDATE'));
    }
    catch ({message}) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: ACTIONS.META, expectAnswer: false, errorMessage: message});
    }
}

function* uploadFileSaga ({file, fieldName}) {
    yield put({type: ACTIONS.META, expectAnswer: true, errorMessage: null});
    // NOTE clear field
    yield put(change('scoringQuestions', fieldName, null));
    try {
        let document = yield call(uploadFile, file);
        yield call(toastr.success, translate('SCORING_QUESTIONS$TITLE'), translate('GLOBALS$SUCCESSFUL_FILE_UPLOAD'));
        yield put(change('scoringQuestions', fieldName, document));
    } catch ({message}) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: ACTIONS.META, errorMessage: message});
    }
    yield put({type: ACTIONS.META, expectAnswer: false});
}

/**
 * get data by system id
 * @param {Number} riskModelId
 * @param {String} metricDomain
 * @private
 */
function getData( riskModelId, metricDomain ) {
    return instanceAPI({
        method: 'post',
        data: {metricDomain: metricDomain === 'all' ? null : metricDomain},
        url: `/risk-model/${riskModelId}/qualitative-questions/organization-filter`
    }).then(success=>prepareData(success));
}

/**
 * get metric domains
 * @private
 */
function getMetricDomains () {
    return instanceAPI({method: 'get', url: '/metric-domains/filtered'}).then(success=>{success.unshift(metricAll); return success;})
}

/**
 * update data
 * @param {Number} riskModelId
 * @param {Object} data
 * @private
 */
function updateData( riskModelId, data ) {
    // NOTE convert 'selectedAnswers' property to array if value is not empty
    let preparedData = cloneDeep(data);
    (preparedData.questions || []).forEach(item => item.selectedAnswers = item.selectedAnswers && [item.selectedAnswers]);
    return instanceAPI({
        method: 'post',
        data: preparedData,
        url: `risk-model/${riskModelId}/qualitative-questions/organization-save/${preparedData.metricDomainCode}`
    }).then(success=>prepareData(success));
}

/**
 * prepare data for view
 * @param {Object} data
 * @private
 */
function prepareData( data ) {
    (data.questions||[]).forEach(item => {
        // NOTE convert 'selectedAnswers' property to object if selectedAnswers is not empty
        item.selectedAnswers = get(item, 'selectedAnswers[0]', null);
        // NOTE sort answers by answer weight
        (item.answers || []).sort((a, b) => get(a, 'answerWeight.value') - get(b, 'answerWeight.value'));
    });
    return data;
}

/**
 * upload file
 * @param {File} file
 * @private
 */
function uploadFile ( file ) {
    const data = new FormData();
    data.append('file', file);
    return instanceAPI({method: 'post', url: '/documents/question_answer/upload', data});
}

/**
 * helper to setup correctness url params
 *
 * @param {Object} reducer
 * @public
 */
function updateLocation({metric}) {
    let params = {};
    // NOTE setup data to url which has difference with default data
    metric !== initial.metric && (params.metric = metric);
    let search = queryService.format(params);
    // NOTE update url if it has difference
    if ( search !== history.location.search ) {
        history.push(history.location.pathname+search);
    }
}

