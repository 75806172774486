// outsource dependencies
import {every, get} from 'lodash';
import {connect} from 'react-redux';
import React, {useEffect, useRef} from 'react';
//import { Prompt } from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {Accordion, AccordionDetails, AccordionSummary, Paper, Tab, Tabs} from '@mui/material';
import {Field, FieldArray, isPristine, reduxForm} from 'redux-form';

// local dependencies
import {LIST, QUESTIONS} from '../actions';
import is from '../../../services/is.service';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {CategoryQuestions, QuestionRow} from '../../../components/question-row';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {CancelBtn, ResetBtn, SubmitBtn, WarningBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {CYBER_RISK_SCORING_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import getSystemName from '../../../helpers/getSystemName';
import {history} from '../../../store';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import {ACTIONS} from "../../cybersecurity-maturity/actions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useReactToPrint} from "react-to-print";
import {getQueryStringParameter} from "../../../helpers/react-utils";

const Questions = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);

    // let isNew = id === NEW_ID;
    let {message, clearError, pristine, hints} = props;
    const breadcrumbMap = {
        ...CYBER_RISK_SCORING_MAP,
        '/private/cyber-risk-scoring/questions': {
            id: 12,
            title: `${props.data.name ? props.data.name + ' / ' : ''} ${translate('SCORING_QUESTIONS$TITLE')}  /  ${props.data.metricDomainCode ? getSystemName(props.data.metricDomainCode) : 'All'}`,
            parent: 11
        },
    };

    return (
        <div style={{height: '100%', overflowY: 'auto'}}>
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={breadcrumbMap}/>
                <ConnectedInitializer>
                    <Row className="offset-top-2">
                        <Col xs={12}>
                            <Row className="offset-bottom-4">
                                <Col xs={12} lg={pristine ? 12 : 8}>
                                    <RichHintTitle
                                        update={LIST}
                                        name={translate('SCORING_QUESTIONS$TITLE')}
                                        expectAnswer={expectAnswer}
                                        data={findHint(hints, 'SCORING_QUESTIONS_TITLE')}
                                    />
                                </Col>
                                {!pristine && <Col xs={12} lg={4}>
                                    <h3 className="text-danger text-right top-indent-1"><strong>
                                        <i className="fa fa-exclamation-triangle"
                                           aria-hidden="true"/> {translate('GLOBALS$UNSAVED_DATA')}
                                    </strong></h3>
                                </Col>}
                            </Row>
                            <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col>
                            </Row>
                            <Row> <Col xs={12}> <ConnectedForm/> </Col> </Row>
                            {/*NOTE block navigating away from a page and show message*/}
                            {/*<Prompt when={!pristine} message={translate('GLOBALS$UNSAVED_DATA_MESSAGE')} />*/}
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        </div>
    );
};

export default connect(
    state => ({
        data: state.cyberRiskScoring.questions.data,
        pristine: isPristine('scoringQuestions')(state),
        message: state.cyberRiskScoring.questions.errorMessage,
        disabled: state.cyberRiskScoring.questions.expectAnswer,
        hints: state.cyberRiskScoring.questions.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: QUESTIONS.CLEAR}),
        initialize: id => dispatch({type: QUESTIONS.INITIALIZE, id}),
        clearError: () => dispatch({type: QUESTIONS.META, errorMessage: null}),
    })
)(Questions);

const ConnectedInitializer = connect(
    state => ({
        initialize: state.cyberRiskScoring.questions.initialized,
        hints: state.cyberRiskScoring.questions.hintsData
    }),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        metric: state.cyberRiskScoring.questions.metric,
        initialValues: state.cyberRiskScoring.questions.data,
        calculations: null,
        disabled: state.cyberRiskScoring.questions.expectAnswer,
        metricDomains: state.cyberRiskScoring.questions.metricDomains,
        questionsLength: get(state, 'cyberRiskScoring.questions.data.questions.length', 0),
        hints: state.cyberRiskScoring.questions.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: QUESTIONS.CANCEL}),
        update: formData => dispatch({type: QUESTIONS.UPDATE, ...formData}),
        changeMetric: metric => dispatch({type: QUESTIONS.UPDATE_METRIC, metric}),
        uploadFile: (file, fieldName) => dispatch({type: QUESTIONS.UPLOAD_FILE, file, fieldName}),
    })
)(reduxForm({
    form: 'scoringQuestions',
    enableReinitialize: true,
    warn: values => {
        const warnings = {};
        let map = {};
        // NOTE build question - answer map
        (values.questions || []).forEach(item => {
            map[item.id] = get(item, 'selectedAnswers.id', null);
        });
        // NOTE check branching logic
        let branchingWarns = [];
        (values.questions || []).forEach((item, index) => {
            let warns = {};
            let branchingLogic = get(item, 'branchingLogic', []);
            let isVisible = every(branchingLogic, branching => {
                const questionId = get(branching, 'question.id'),
                    answerId = get(branching, 'answer.id');
                return map[questionId] === answerId;
            });
            if (!isVisible) {
                warns.isHidden = true;
            }
            branchingWarns[index] = warns;
        });
        if (!is.empty(branchingWarns)) {
            warnings.questions = branchingWarns;
        }
        return warnings;
    }
})(({
    handleSubmit,
    invalid,
    pristine,
    disabled,
    update,
    reset,
    questionsLength,
    calculations,
    initialValues,
    uploadFile,
    changeMetric,
    metric,
    metricDomains,
    cancel,
    hints
}) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true,
    });

    const back = getQueryStringParameter('back');

    return (
        <form autoComplete="off" name="scoringQuestions" onSubmit={handleSubmit(update)}>
            <Paper square>
                <Row>
                    <Col xs={10}>
                        <Tabs
                            value={metric}
                            textColor="primary"
                            variant="scrollable"
                            indicatorColor="primary"
                            onChange={(e, value) => changeMetric(value)}
                        >
                            {metricDomains.map((item, key) => <Tab key={key} value={item.code} label={item.name}/>)}
                        </Tabs>
                    </Col>
                    <Col xs={2}>
                        <div className="offset-top-2" style={{float: 'right', marginRight: 30}}>
                            <WarningBtn onClick={handlePrint} tooltip={"Print"} hint={findHint(hints, `BUTTON_QUESTIONS_PRINT`)} >
                                <i className="fa fa-print" aria-hidden="true"/>
                            </WarningBtn>
                            {back &&
                                <WarningBtn onClick={() => history.push(back)} tooltip={translate('GLOBALS$BACK')} hint={findHint(hints, `BUTTON_QUESTIONS_BACK`)} >
                                    <i className="fa fa-reply" aria-hidden="true"/>
                                </WarningBtn>
                            }
                        </div>
                    </Col>
                </Row>
            </Paper>
            <Paper square className="indent-5"><Container fluid>
                <Row className="offset-bottom-4" ref={componentRef}>
                    {calculations && (calculations && calculations[metric] && calculations[metric].hideCalculation ? "" : (
                        <Col xs={12} className="text-center text-uppercase offset-bottom-6">
                            <RichHintTitle classes="h3" placement="bottom" update={ACTIONS}  name={'Calculated value:'} expectAnswer={disabled} data={findHint(hints, 'CYBERSECURITY_MATURITY_CALCULATED_VALUE')}/>
                            <span className="h3 text-uppercase">
                            {(calculations && calculations[metric] && calculations[metric].resultNormalized
                                ? Number(calculations[metric].resultNormalized * 100).toFixed(2) : "N/A")}
                        </span>
                        </Col>
                    ))}
                    <Col xs={12}>
                        {!questionsLength ? (
                            <h3 className="text-center text-uppercase offset-bottom-6"> {translate('GLOBALS$NO_QUESTIONS_FOUND')} </h3>
                        ) : questionsLength < 127 || initialValues.categories.length < 2 ? (
                            <FieldArray name="questions" component={Items} uploadFile={uploadFile}
                                        initialValues={initialValues} disabled={disabled}/>
                        ) : (
                            <ItemsInCategories uploadFile={uploadFile} disabled={disabled}/>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-right">
                        <SubmitBtn disabled={pristine || invalid || disabled} className="offset-right-2"
                                   hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_SAVE')}/>
                        <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"
                                  hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_RESET')}/>
                        <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_CANCEL')}/>
                    </Col>
                </Row>
            </Container></Paper>
        </form>
)
})));

/**
 * component for item of "questions" list
 *
 * @param {Object} props
 * @private
 */
const Items = ({fields, disabled, uploadFile}) => {
    return fields.map((mKey, index) => (
        <Field key={index} name={mKey} component={QuestionRow} disabled={disabled} uploadFile={uploadFile}/>));
};


/**
 * component for item of "questions" list in category
 *
 * @param {Object} props
 * @private
 */
const CategoryItems = ({fields, disabled, uploadFile, questionCategory}) => {
    return fields.map((mKey, index) => (
        <Field key={index} name={mKey} component={CategoryQuestions} category={questionCategory} disabled={disabled}
               uploadFile={uploadFile}/>));
};

const ItemsInCategories = withTranslation(connect(
    state => ({categories: state.cybersecurityMaturity.data.categories}),
)(({categories, disabled, uploadFile}) => (
    categories.map((item, index) => {
        return (
            <Accordion key={item.categoryName} defaultExpanded={(index === 0)} TransitionProps={{unmountOnExit: true}}>
                <AccordionSummary className="header-row-title" expandIcon={<ExpandMoreIcon sx={{color: '#FFFFFF'}} />}>
                    {`${item.categoryName}`}
                </AccordionSummary>
                <AccordionDetails>
                    <FieldArray name="questions" component={CategoryItems}
                                questionCategory={item.categoryName}
                                uploadFile={uploadFile}
                                disabled={disabled}/>
                </AccordionDetails>
            </Accordion>);
    })
)));
